import React from 'react'
import { connect } from 'react-redux'

import Layout from 'layout'
import * as actionCreators from '../store/actions'
import { NotFoundPage } from './404'

export class Info extends React.Component {
  render() {
    const { device } = this.props
    const production = process.env.NODE_ENV === 'production'

    if (production) {
      return <NotFoundPage />
    }

    return (
      <Layout>
        <div style={{ padding: '100px 50px' }}>
          <h3>System Info</h3>
          <div>
            <span>
              <strong>iOS:</strong>
            </span>
            <span>{JSON.stringify(device.iOS)}</span>
          </div>
          <div>
            <span>
              <strong>isSafari:</strong>
            </span>
            <span>{JSON.stringify(device.isSafari)}</span>
          </div>
          <div>
            <span>
              <strong>platform:</strong>
            </span>
            <span>{device.platform}</span>
          </div>
        </div>
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  return {
    device: state.device,
  }
}

export default connect(mapStateToProps, actionCreators)(Info)
